import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import Page from '../components/Page';

const Banner = styled.div`
  height: 300px;
  padding-top: 75px;
  background: #dddddd;
`;

const Countries = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding: 30px 50px;
  align-items: center;
  justify-content: space-between;
`;

const CountryCard = styled(Link)`
  min-width: 200px;
  max-width: 225px;
  margin: 10px;
  position: relative;
  text-align: center;
  min-height: 200px;
  margin-top: 40px;
`;
const CountryFlag = styled.img`
  width: 100%;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 2px 2px 2px #d8d8d8;

  :hover {
    box-shadow: 1px 1px 1px 1px #6a6a6a;

  }
`;
const CountryTitle = styled.h3`
  position: absolute;
  bottom: 0;
  margin-top: 20px;
  width: 100%;
`;

const CategoryTemplate = ({ pageContext: context }) => {
  const { countries, name } = context;
  countries.sort((a, b) => {
    if (a.title > b.title) {
      return 1;
    }
    if (b.title > a.title) {
      return -1;
    }
    return 0;
  });

  return (
    <Layout>
      {/* TODO: Add SEO component */}
      <Page.Wrapper>
        <Banner>
          <Page.Title>{name}</Page.Title>
        </Banner>
        <Page.Content top>
          {/* eslint-disable-next-line react/no-danger */}
          <Countries>
            {countries.map((country) => (
              <CountryCard key={country.id} to={`/countries/${country.slug}`}>
                <CountryFlag alt={country.title} src={country.featuredImage.sourceUrl} />
                <CountryTitle>{country.title}</CountryTitle>
              </CountryCard>
            ))}
          </Countries>
        </Page.Content>
      </Page.Wrapper>
    </Layout>
  );
};

CategoryTemplate.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};

export default CategoryTemplate;
